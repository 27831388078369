export default[
    {
        path: '/trabajador',
        component: () => import(/* webpackChunkName: "trabajador" */'@/views/trabajador/TrabajadorMain.vue'),
        meta: {
            requiresAuth: true,
            roles:['ROLE_TRABAJADOR'],
        },
        children:[
            {
                path:'/',
                name:'trabajadorPrincipal',
                component: () => import(/* webpackChunkName: "trabajadorPrincipal" */'@/views/trabajador/TrabajadorPrincipal.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_TRABAJADOR'],
                },
            },
            {
                path:'credencial',
                name:'trabajadorCredencial',
                component: () => import(/* webpackChunkName: "trabajadorCredencial" */'@/views/trabajador/TrabajadorCredencial.vue'),
                meta: {
                    requiresAuth: false,
                    roles:['ROLE_ADMIN'],
                },
            },
            {
                path:':trb',
                name:'trabajadorDetalle',
                component: () => import(/* webpackChunkName: "trabajadorDetalle" */'@/views/trabajador/TrabajadorDetalle.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_TRABAJADOR'],
                },
            },
        ]
    }
]