import NoVotaron from '@/generales/NoVotaron'
export default{
    namespaced: true,
    state:{
        paramsList:{},
        queryList:null,
        optionsTableList:{},
        panelBusqueda:false,
        noVotaron:NoVotaron,
    },
    mutations:{
        CAMBIA_PARAMSLIST(state, data){
            state.paramsList = data
        },
        CAMBIA_QUERYLIST(state, data){
            state.queryList = data
        },
        CAMBIA_OPTIONSTABLELISTLIST(state, data){
            state.optionsTableList = data
        },
        CAMBIA_PANELBUSQUEDA(state, data){
            state.panelBusqueda = data
        },
    },
    actions:{},
}