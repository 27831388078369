export default[
    {
        path: '/bolsaTrabajo',
        component: () => import(/* webpackChunkName: "bolsatrabajo" */'@/views/bolsatrabajo/BolsaTrabajoMain.vue'),
        children:[
            {
                path: '/',
                name: 'bolsaTrabajoMenu',
                component: () => import(/* webpackChunkName: "bolsaTrabajoMenu" */'@/views/bolsatrabajo/BolsaTrabajoMenu.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_TRABAJADOR'],
                },
            },

            {
                path:'ampjornada',
                name:'ampliacionJornada',
                component: () => import(/* webpackChunkName: "bolsaTrabajoAmpJornada" */'@/views/bolsatrabajo/nominaciones/AmpJorOrd.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_TRABAJADOR'],
                },
            },
            {
                path:'cambioareaord',
                name:'cambioAreaOrd',
                component: () => import(/* webpackChunkName: "bolsaTrabajoCambioAreaOrd" */'@/views/bolsatrabajo/nominaciones/CambioAreaOrd.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_TRABAJADOR'],
                },
            },
            {
                path:'cambiorama',
                name:'CambioRamaOrd',
                component: () => import(/* webpackChunkName: "bolsaTrabajoCambioRamaOrd" */'@/views/bolsatrabajo/nominaciones/CambioRamaOrd.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_TRABAJADOR'],
                },
            },
            {
                path:'cambiotipoplaza',
                name:'CambioTipoPlazaOrd',
                component: () => import(/* webpackChunkName: "bolsaTrabajoCambioTipoPlazaOrd" */'@/views/bolsatrabajo/nominaciones/CambioTipoPlazaOrd.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_TRABAJADOR'],
                },
            },
            {
                path:'nuevoingreso',
                name:'NuevoIngresoOrd',
                component: () => import(/* webpackChunkName: "bolsaTrabajoNuevoIngresoOrd" */'@/views/bolsatrabajo/nominaciones/NuevoIngresoOrd.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_TRABAJADOR'],
                },
            },
            {
                path:'cambioadsc',
                name:'cambioAdsc',
                component: () => import(/* webpackChunkName: "bolsaTrabajoCambioAdsc" */'@/views/bolsatrabajo/nominaciones/CambioAdscOrd.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_TRABAJADOR'],
                },
            },
            {
                path:'nominacionesegc',
                name:'nominacionesEgc',
                component: () => import(/* webpackChunkName: "bolsaTrabajoNominacionesEgc" */'@/views/bolsatrabajo/BolsaTrabajoNominacionesEgc.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_TRABAJADOR'],
                },
            },
            {
                path:'nominaciones/:id',
                name:'bolsaTrabajoNominaciones',
                component: () => import(/* webpackChunkName: "bolsaTrabajoNominaciones" */'@/views/bolsatrabajo/BolsaTrabajoNominaciones.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_TRABAJADOR'],
                },
            },

        ]
    }
]