export default[
    {
        path: '/conflictos',
        component: () => import(/* webpackChunkName: "conflictos" */'@/views/conflictos/ConflictosMain.vue'),
        meta: {
            requiresAuth: true,
            roles:['ROLE_TRABAJADOR'],
        },
        children:[
            {
                path:'/',
                name:'conflictosMain',
                component: () => import(/* webpackChunkName: "conflictosMenu" */'@/views/conflictos/ConflictosMenu.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_TRABAJADOR'],
                },
            },
            {
                path:'queja',
                name:'conflictosQueja',
                component: () => import(/* webpackChunkName: "conflictosQueja" */'@/views/conflictos/ConflictosQueja.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_TRABAJADOR'],
                },
            },
        ]
    },
]