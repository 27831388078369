export default{
    namespaced: true,
    state:{
        turnos:[
            {value:1, text:"Matutino"},
            {value:2, text:"Vespertino"},
            {value:3, text:"Nocturno"},
            {value:4, text:"Movil"},
            {value:5, text:"Jornada Acumulada"},
            {value:99, text:"OTRO"},
        ],
    },
    mutations:{},
    actions:{},
}