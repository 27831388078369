import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#416d5e',
                //secondary: '#b0bec5',
                //accent: '#8c9eff',
                error: '#9F000E',
            },
        },
    },
    lang: {
        locales: { es },
        current: 'es',
    },

});
