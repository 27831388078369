export default[
    {
        path: '/generales',
        component: () => import(/* webpackChunkName: "Generales" */'@/views/generales/GeneralesMain.vue'),
        meta: {
            //requiresAuth: true,
            //roles:['ROLE_TRABAJADOR'],
        },
        children:[
            {
                path:'calif',
                name:'trabajadorCalificacion',
                component: () => import(/* webpackChunkName: "trabajadorCalificacione" */'@/views/generales/GeneralesTrabajadorCalificacion.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_TRABAJADOR'],
                },
            },
            {
                path:'estatuto',
                name:'generalesEstatuto',
                component: () => import(/* webpackChunkName: "generalesEstatuto" */'@/views/generales/GeneralesEstatuto.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_TRABAJADOR'],
                },
            },
            {
                path:'faltas',
                name:'generalesFaltas',
                component: () => import(/* webpackChunkName: "generalesFalta" */'@/views/generales/GeneralesFaltas.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_TRABAJADOR'],
                },
            },
            {
                path:'padron',
                name:'generalesPadron',
                component: () => import(/* webpackChunkName: "generalesPadron" */'@/views/generales/GeneralesPadron.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_TRABAJADOR'],
                },
            },
        ]
    }
]