import Swal from "sweetalert2"
import 'sweetalert2/dist/sweetalert2.min.css'

export default{
    namespaced: true,
    state:{
        username:'',
        roles:[],
        token:"",
        datosTrabajador:{},
        ventanaLogin:false,
    },
    mutations:{
        ABRE_VENTANA_LOGIN(state, d){
            state.ventanaLogin = d
        },
        ACTUALIZA_DATOS_USUARIO(state){
            state.username = localStorage.getItem('username')
            if (localStorage.getItem('roles')){
                state.roles = localStorage.getItem('roles').split(',')
            } else {
                state.roles=[]
            }

        },

        CIERRA_SESION(state){
            localStorage.removeItem('username');
            localStorage.removeItem('roles');
            localStorage.removeItem('token');

            state.username = ''
            state.roles = []

            this.$router.push({path:'/'})
        },
    },
    actions:{
         /* **********
        *
        * Carga antes de cualquier vista
        *
        ********** */
        async cargaAntes(context, data){
            let datosLogin={}
            try{
                datosLogin = await this.Axios.get('principal/auth')
                // Si Tiene sesión iniciada
                if (datosLogin.data.loggedIn){
                    // Carga los datos al localstore
                    context.state.username = localStorage.getItem('username')
                    context.state.roles = localStorage.getItem('roles').split(',')
                    context.state.token = localStorage.getItem('token')

                    context.state.datosTrabajador = datosLogin.data.datosTrabajador

                    // Si la ruta requiere login
                    if (data.to.meta.requiresAuth){
                        // Verifica que existan los roles
                        if (localStorage.getItem('roles')){
                            let resp = false

                            let rolesTodos = localStorage.getItem('roles').split(',')

                            // Agrega los roles que sean DE CAJÓN
                            data.to.meta.roles.push('ROLE_ADMIN')

                            // Recorre los roles para ver si estan incluidos
                            data.to.meta.roles.forEach(role=>{

                                if (rolesTodos.includes(role)){
                                    resp = true
                                }
                            })

                            if (resp){
                                data.next()
                            } else {
                                //alert('No puede acceder a este contenido')
                                Swal.fire({
                                    icon: 'error',
                                    title: 'No se puede acceder',
                                    text: 'No tiene privilegios para acceder.',
                                })
                                data.next('/')

                            }
                        // Si no existen los roles, manda a raiz
                        } else {
                            data.next('/')
                        }
                    // Si no requiere autentificación, deja pasar
                    } else {
                        data.next()
                    }
                } else {
                    localStorage.removeItem('username');
                    localStorage.removeItem('roles');
                    localStorage.removeItem('token');
                    data.next('/')
                }
            }catch{
                localStorage.removeItem('username');
                localStorage.removeItem('roles');
                localStorage.removeItem('token');
                data.next('/')
            }
        },
        /* **********
        *
        * Inicia sesión. Si es exitoso, carga los datos en el localStore uy entrega 1
        * Si no,
        *
        ********** */
        async doLogin(context, data){
            var respuesta = {
                success:false,
                codigo:'',
                mensaje:'',
                estado:0
            }

            try{
                let datosLogin = await this.$http({
                    url: '/login',
                    method: 'POST',
                    data: data,
                })

                context.state.username = datosLogin.data.username
                context.state.roles = datosLogin.data.roles
                context.state.token = datosLogin.data.access_token

                localStorage.setItem('token',datosLogin.data.access_token)
                localStorage.setItem('roles',datosLogin.data.roles)
                localStorage.setItem('username',datosLogin.data.username)

                this.$http.defaults.headers.common['X-Auth-Token']=datosLogin.data.access_token

                respuesta.success = true
                respuesta.estado = 1
                //respuesta.mensaje = datosLogin.statusText
                respuesta.codigo = datosLogin.status

            }catch(err){
                respuesta.success = false

                if (err.message == "Network Error"){
                    respuesta.mensaje = 'No es posible establecer comunicación con el servidor'

                } else {
                    switch(err.response.status){

                        case 401:
                            //context.state.ventanaLogin = true
                            respuesta.mensaje = 'Nombre de usuario o contraseña incorrecta'
                        break
                        case 403:
                            respuesta.mensaje = 'No tiene los permiso para esta acción'
                        break

                        default:
                            respuesta.mensaje = 'No es posible establecer ejecutar la instrucción. Error '+err.response.status
                        break
                    }

                }
            }
            return respuesta
        },

        revisaError(context, err){
            /**
            * Esta acción se llama... this.$store.dispatch('security/revisaError',err)
             */
            console.log(err)
            if (err.response){
                console.log('Response')
                console.log(err.response)
            }
            if (err.request){
                console.log('Request')
                console.log(err.request)
            }

            if (err.message == "Network Error"){
                Swal.fire({
                    icon: 'error',
                    title: 'No es posible establecer comunicación con el servidor',
                    text: 'Error de red',
                })
            } else {
                if (err.response.status){
                    switch(err.response.status){

                        case 401:
                            context.state.ventanaLogin = true
                        break

                        case 403:
                            Swal.fire({
                                icon: 'warning',
                                title: 'No tiene privilegios para realizar esta acción ',
                            })
                        break

                        default:
                            Swal.fire({
                                icon: 'error',
                                title: 'No es posible establecer ejecutar la instrucción.',
                                text: 'Error '+err.response.status,
                            })
                        break
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'No es posible ejecutar la instrucción.',
                        text: 'Error '+err,
                    })
                }

            }
        },
    },
    getters:{
        datosUsuario(state){
            return {username:state.username, roles:state.roles}
        },
        isLogedIn(state){
            if (state.username){
                return true
            } else {
                return false
            }
        },
        ventanaLogin(state){
            return state.ventanaLogin
        },
    },
}