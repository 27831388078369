export default[
    {
        path: '/juguetes',
        component: () => import(/* webpackChunkName: "juguetes" */'@/views/juguetes/JuguetesMain.vue'),
        meta: {
            requiresAuth: true,
            roles:['ROLE_TRABAJADOR'],
        },
        children:[
            {
                path:'/',
                name:'juguetesHome',
                component: () => import(/* webpackChunkName: "juguetesHome" */'@/views/juguetes/JuguetesHome.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_TRABAJADOR'],
                },
            },
            {
                path:'registro',
                name:'juguetesRegistro',
                component: () => import(/* webpackChunkName: "juguetesHome" */'@/views/juguetes/JuguetesRegistro.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_TRABAJADOR'],
                },
            },
        ]
    },
]