export default[
    {
        path: '/admin',
        component: () => import(/* webpackChunkName: "admin" */'@/views/admin/AdminMain.vue'),
        meta: {
            requiresAuth: true,
            roles:['ROLE_ADMIN'],
        },
        children:[
            {
                path:'/',
                name:'adminMain',
                component: () => import(/* webpackChunkName: "adminPrincipal" */'@/views/admin/AdminPrincipal.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_ADMIN'],
                },
            },
            {
                path:'trabajador/:trb',
                name:'adminTrabajadorId',
                component: () => import(/* webpackChunkName: "adminTrabajadorId" */'@/views/trabajador/TrabajadorDetalle'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_ADMIN'],
                },
            },
            {
                path:'trabajadores',
                name:'adminUsuarios',
                component: () => import(/* webpackChunkName: "adminUsuarios" */'@/views/admin/AdminTrabajadores.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_ADMIN'],
                },
            },
            {
                path:'reportes',
                component: () => import(/* webpackChunkName: "adminReportes" */'@/views/admin/AdminReportes.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_ADMIN'],
                },
                children:[
                    {
                        path:'/',
                        name:'adminReportesMain',
                        component: () => import(/* webpackChunkName: "adminReportes" */'@/views/admin/reportes/AdminReportesCumple'),
                        meta: {
                            requiresAuth: true,
                            roles:['ROLE_ADMIN'],
                        },
                    },
                    {
                        path:'cumple',
                        name:'adminReportesCumple',
                        component: () => import(/* webpackChunkName: "adminReportesCumple" */'@/views/admin/reportes/AdminReportesCumple'),
                        meta: {
                            requiresAuth: true,
                            roles:['ROLE_ADMIN'],
                        },
                    },
                    {
                        path:'festejo',
                        name:'adminReportesFestejo',
                        component: () => import(/* webpackChunkName: "adminReportesFestejo" */'@/views/admin/reportes/AdminReportesFestejo'),
                        meta: {
                            requiresAuth: true,
                            roles:['ROLE_ADMIN'],
                        },
                    },
                    {
                        path:'nominaciones',
                        name:'adminReportesNominaciones',
                        component: () => import(/* webpackChunkName: "adminReportesNominaciones" */'@/views/bolsatrabajo/BolsaTrabajoNominacionesTodos'),
                        meta: {
                            requiresAuth: true,
                            roles:['ROLE_ADMIN'],
                        },
                    },
                    {
                        path:'boletos',
                        name:'adminReporteBoletos',
                        component: () => import(/* webpackChunkName: "adminBoletos" */'@/views/boletos/BoletoReporte.vue'),
                        meta: {
                            requiresAuth: true,
                            roles:['ROLE_ADMIN'],
                        },
                    },
                    {
                        path:'quejas',
                        name:'adminReporteQuejas',
                        component: () => import(/* webpackChunkName: "adminQuejas" */'@/views/admin/reportes/AdminReportesQuejas.vue'),
                        meta: {
                            requiresAuth: true,
                            roles:['ROLE_ADMIN'],
                        },
                    },
                    {
                        path:'juguetes',
                        name:'adminReporteJuguetes',
                        component: () => import(/* webpackChunkName: "adminQuejas" */'@/views/admin/reportes/AdminReportesJuguetes.vue'),
                        meta: {
                            requiresAuth: true,
                            roles:['ROLE_ADMIN'],
                        },
                    },
                ],
            },
            {
                path: 'catalogos',
                component: () => import(/* webpackChunkName: "catalogos" */'@/views/admin/AdminCatalogos.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_ADMIN'],
                },
                children:[
                    {
                        path:'/',
                        name:'catalogoPuestosMain',
                        component: () => import(/* webpackChunkName: "catalogosPrincipal" */'@/views/admin/catalogos/CatalogoPuestos.vue'),
                        meta: {
                            requiresAuth: true,
                            roles:['ROLE_ADMIN'],
                        },
                    },
                    {
                        path:'puestos',
                        name:'catalogoPuestos',
                        component: () => import(/* webpackChunkName: "catalogosPuestos" */'@/views/admin/catalogos/CatalogoPuestos.vue'),
                        meta: {
                            requiresAuth: true,
                            roles:['ROLE_ADMIN'],
                        },
                    },
                    {
                        path:'adscripciones',
                        name:'catalogoAdscripciones',
                        component: () => import(/* webpackChunkName: "catalogosAdscripcios" */'@/views/admin/catalogos/CatalogoAdscripciones.vue'),
                        meta: {
                            requiresAuth: true,
                            roles:['ROLE_ADMIN'],
                        },
                    },
                ]
            }
        ]
    }
]