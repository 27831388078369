export default[
    {
        path: '/boletos',
        component: () => import(/* webpackChunkName: "boletos" */'@/views/boletos/BoletosMain.vue'),
        meta: {
            requiresAuth: true,
            roles:['ROLE_TRABAJADOR'],
        },
        children:[
            {
                path:'/',
                name:'boletosMain',
                component: () => import(/* webpackChunkName: "boletosPrincipal" */'@/views/boletos/BoletosPrincipal.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_TRABAJADOR'],
                },
            },
        ]
    },
]