import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import Axios from 'axios'
import numeral from 'numeral'
import moment from 'moment'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import VueApexCharts from 'vue-apexcharts'

Vue.config.productionTip = false

Vue.prototype.$swal = Swal
Vue.prototype.$http = Axios
Vue.prototype.Axios = Axios
Vue.prototype.$numeral = numeral
Vue.prototype.moment = moment
Vue.prototype.$url= window.location.origin

Vue.use(VueApexCharts)

moment.locale('es_MX')

// Configuración para AXIOS
if (process.env.NODE_ENV == 'development'){
    Axios.defaults.baseURL = 'http://localhost:9999/api'
    Vue.prototype.$baseUrl = 'http://localhost:9999/'
} else {
    Axios.defaults.baseURL = 'https://api.sntss28.org.mx/sntss28bk/api'
    Vue.prototype.$baseUrl = 'https://api.sntss28.org.mx/sntss28bk/'
}

Axios.defaults.timeout = 120000

if (localStorage.getItem('token')){
    Vue.prototype.$http.defaults.headers.common['X-Auth-Token']=localStorage.getItem('token')
}

store.$http = Vue.prototype.$http
store.Axios = Axios

store.$router = router



router.beforeEach((to, from, next) => {
    // ----------------------------------------------------------------------------------------
    // La limpieza que se tiene que hacer de los datos del store antes de cargar cada vista
    // ----------------------------------------------------------------------------------------
    if(to.path=='/signin'){
        localStorage.removeItem('username')
        localStorage.removeItem('roles')
        localStorage.removeItem('token')
    }
    if (to.path == '/'){
        //console.log(localStorage.getItem('username'))
        if (localStorage.getItem('username')){
                next('/principal')
            } else {
                next()
            }

    } else {
        if(to.meta.requiresAuth){
            store.dispatch('security/cargaAntes', {to:to, from:from, next:next})
        } else {
            next()
        }
    }

})

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
