export default[
    {
        path: '/becas',
        component: () => import(/* webpackChunkName: "becas" */'@/views/becas/BecasMain.vue'),
        children:[
            {
                path: '/',
                name: 'becasMenu',
                component: () => import(/* webpackChunkName: "becasMenu" */'@/views/becas/BecasMenu.vue'),
                meta: {
                    requiresAuth: true,
                    roles:['ROLE_TRABAJADOR'],
                },
            },
        ],
    },
]